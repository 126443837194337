import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { CRISP_WEBSITE_ID_PERS } from "@buddieshr/utils/constants";

const CrispChat = () => {
  useEffect(() => {
    Crisp.configure(CRISP_WEBSITE_ID_PERS);
  });

  return null;
};

export default CrispChat;
